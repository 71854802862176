var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',[_c('md-card',[_c('md-card-content',[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('TreatmentsDropdown',{staticClass:"road-class-select-dropdown",attrs:{"label":_vm.$t('road_network.treatment_id'),"data-vv-name":"treatment_id","initial_value":_vm.treatment_id,"is_required":true},model:{value:(_vm.treatment_id),callback:function ($$v) {_vm.treatment_id=$$v},expression:"treatment_id"}}),_c('md-field',{class:[
          { 'md-valid': !_vm.errors.has('criteria_set') }, { 'md-error': _vm.errors.has('criteria_set') }
        ]},[_c('label',[_vm._v(_vm._s(_vm.$t('road_network.criteria_set')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.criteria_set),expression:"modelValidations.criteria_set"}],attrs:{"type":"number","data-vv-name":"criteria_set","required":"","md-numeric":""},model:{value:(_vm.criteria_set),callback:function ($$v) {_vm.criteria_set=$$v},expression:"criteria_set"}})],1),_c('ThresholdVariableDropdown',{staticClass:"road-class-select-dropdown",attrs:{"label":_vm.$t('road_network.treatment_id'),"data-vv-name":"threshold_variable","initial_value":_vm.threshold_variable,"is_required":true},model:{value:(_vm.threshold_variable),callback:function ($$v) {_vm.threshold_variable=$$v},expression:"threshold_variable"}}),_c('md-field',{class:[
          { 'md-valid': !_vm.errors.has('low_threshold') }, { 'md-error': _vm.errors.has('low_threshold') }
        ]},[_c('label',[_vm._v(_vm._s(_vm.$t('road_network.low_threshold')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.low_threshold),expression:"modelValidations.low_threshold"}],attrs:{"type":"number","data-vv-name":"low_threshold","required":"","md-numeric":""},model:{value:(_vm.low_threshold),callback:function ($$v) {_vm.low_threshold=$$v},expression:"low_threshold"}})],1),_c('md-field',{class:[
          { 'md-valid': !_vm.errors.has('high_threshold') }, { 'md-error': _vm.errors.has('high_threshold') }
        ]},[_c('label',[_vm._v(_vm._s(_vm.$t('road_network.high_threshold')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.high_threshold),expression:"modelValidations.high_threshold"}],attrs:{"type":"number","data-vv-name":"high_threshold","required":"","md-numeric":""},model:{value:(_vm.high_threshold),callback:function ($$v) {_vm.high_threshold=$$v},expression:"high_threshold"}})],1),_c('md-field',{class:[
          { 'md-valid': !_vm.errors.has('number_units') }, { 'md-error': _vm.errors.has('number_units') }
        ]},[_c('label',[_vm._v(_vm._s(_vm.$t('road_network.number_units')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.number_units),expression:"modelValidations.number_units"}],attrs:{"type":"number","data-vv-name":"number_units","required":"","md-numeric":""},model:{value:(_vm.number_units),callback:function ($$v) {_vm.number_units=$$v},expression:"number_units"}})],1),_c('UnitsDropdown',{staticClass:"road-class-select-dropdown",attrs:{"label":_vm.$t('road_network.unit_id'),"data-vv-name":"unit","initial_value":_vm.unit,"is_required":true},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}})],1),_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"},[_c('md-field',{class:[
          { 'md-valid': !_vm.errors.has('unit_cost_kgs') }, { 'md-error': _vm.errors.has('unit_cost_kgs') }
        ]},[_c('label',[_vm._v(_vm._s(_vm.$t('stdCols.unit_cost_kgs')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.unit_cost_kgs),expression:"modelValidations.unit_cost_kgs"}],attrs:{"type":"number","data-vv-name":"unit_cost_kgs","required":""},model:{value:(_vm.unit_cost_kgs),callback:function ($$v) {_vm.unit_cost_kgs=$$v},expression:"unit_cost_kgs"}})],1),_c('md-field',{class:[
          { 'md-valid': !_vm.errors.has('updated_year') }, { 'md-error': _vm.errors.has('updated_year') }
        ]},[_c('label',[_vm._v(_vm._s(_vm.$t('stdCols.updated_year')))]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:(_vm.modelValidations.updated_year),expression:"modelValidations.updated_year"}],attrs:{"type":"number","data-vv-name":"updated_year","required":""},model:{value:(_vm.updated_year),callback:function ($$v) {_vm.updated_year=$$v},expression:"updated_year"}})],1)],1),_c('div',{staticClass:"md-layout md-size-50"},[_c('div',{staticClass:"md-layout-item md-small-size-100 md-size-50"}),_c('div',{staticClass:"md-layout-item md-size-100 text-right"},[_c('md-button',{staticClass:"md-success",attrs:{"native-type":"submit","disabled":_vm.saveBtnDisabled},nativeOn:{"click":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.save'))+" ")]),_c('md-button',{staticClass:"md-accent",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onClose.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('buttons.close'))+" ")])],1)])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }