<!--
File: ThresholdVariableDropdown.vue
Description: show the dropdown combo with the threshold variables dropdown list .
-->
<template>
  <md-field>
    <label for="key">{{ label }}</label>
    <md-select v-model='key' name="key" id="key" :disabled="disabled" :required="is_required" @md-selected='onChange'>
      <md-option v-for='(tr, ind) in variables' :key='ind' :value='tr.key'>
        {{ tr.description }}
      </md-option>
    </md-select>
  </md-field>
</template>
<script>
  export default {
    name: 'threshold-variables-dropdown',

    props: {
      label: { default: null, type: String },
      initial_value: { default: null, type: String },   // type: Number },
      is_required: { default: false, type: Boolean },
      disabled: { default: false, type: Boolean }
    },

    data() {
      return {
        key: null,
        variablesListLoaded: false
      }
    },

    mounted() {
      this.$store.dispatch('GET_THRESHOLD_VARIABLES_DROPDOWN', this.is_required).then(() => {
        this.variablesListLoaded = true
        if (this.initial_value && !this.key) this.key = this.initial_value
      })
    },

    methods: {
      onChange() {
        const desc = !this.key ? '' : this.variables.find((element) => element.key === this.key).description

        this.$emit('input', this.key, desc)
      },
    },

    computed: {
      variables() {
        return this.$store.state.Treatments.variablesList;
      }
    },

    watch: {
      initial_value(newValue, oldValue) {
        if (newValue !== oldValue && this.variablesListLoaded) {
          this.key = newValue
        }
      },
    }
  }
</script>